import { render, staticRenderFns } from "./warehouses.pug?vue&type=template&id=e096cf5c&scoped=true&lang=pug&"
import script from "./warehouses.ts?vue&type=script&lang=ts&"
export * from "./warehouses.ts?vue&type=script&lang=ts&"
import style0 from "./warehouses.scss?vue&type=style&index=0&id=e096cf5c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e096cf5c",
  null
  
)

export default component.exports