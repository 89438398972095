// node_module dependencies
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import FormData from 'form-data'
import forEach from 'lodash/forEach'
import upperCase from 'lodash/upperCase'
import startCase from 'lodash/startCase'
import replace from 'lodash/replace'
import join from 'lodash/join'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import find from 'lodash/find'

// Base Page
import BasePage from '@/pages/Base'

// Local Components
import ProductDetail from '@/containers/ProductDetail/ProductDetail.vue'

// Services
import WarehouseService from '@/services/Warehouses/warehouses'
import SuppliersService from '@/services/Suppliers'
import ProvincesService from '@/services/Province'
import CityService from '@/services/City'
import InboundsService from '@/services/Warehouses/inbounds'

@Component({
  components: { ProductDetail }
})
export default class Warehouses extends BasePage {
  constructor() {
    super()
  }

  // Filter
  filterLoading: boolean = false
  filterLabel: string = 'Filter by Warehouse'
  filterHint: string = 'Apply Warehouse Filters Here'
  filterItems: object[] = []
  filterIcon: string = 'mdi-filter-variant'
  filterSelected: string = ''

  // Search
  searchLoading: boolean = false
  searchInput: string = ''
  searchLabel: string = 'Search here...'
  searchHint: string = 'Search Anything Here'
  searchIcon: string = 'search'

  // Add New Warehouse
  addWarehouseDialog: boolean = false
  warehouseName: string = ''
  warehouseAddress: string = ''
  warehouseOptionalAddress: string = ''
  warehouse: string = ''
  warehouseNameHint : string = 'Your new warehouse name'
  warehouseAddressHint : string = 'Your new warehouse address'
  maxWarehouseNameCharacter: number = 50
  maxWarehouseAddressCharacter: number = 50
  countryOriginItems: any = []
  warehouseDialogData: any = {
    loadingCountry: false,
    keywordCountry: null
  }
  warehouseCountryId: any = null

  provinceOriginItems: any = []
  warehouseDialogDataProvince: any = {
    loadingProvince: false,
    keywordProvince: null
  }
  warehouseProvinceId: any = null

  cityOriginItems: any = []
  warehouseDialogDataCity: any = {
    loadingCity: false,
    keywordCity: null
  }
  warehouseCityId: any = null


  // "name": "required",
  // "address": "required",
  // "address_2": "optional",
  // "country_id": "required",
  // "province_id": "required",
  // "city_id": "required",
  // "owned_by_ali": boolean

  // Table
  tableHeaders: object[] = [
    {
      text: 'Warehouse Name',
      align: 'left',
      sortable: false,
      value: 'name'
    },
    {
      text: 'Address',
      align: 'left',
      sortable: true,
      value: 'address'
    },
    {
      text: 'Address (Optional)',
      align: 'left',
      sortable: true,
      value: 'address_2'
    },
    {
      text: 'Country',
      align: 'left',
      sortable: true,
      value: 'country_id'
    },
    {
      text: 'Province',
      align: 'left',
      sortable: true,
      value: 'province_id'
    },
    {
      text: 'City',
      align: 'left',
      sortable: true,
      value: 'city_id'
    },
    {
      text: 'Owned by ALI',
      align: 'left',
      sortable: true,
      value: 'owned_by_ali'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'status',
      class: 'action-table-width'
    }
  ]
  tableItems: any = []
  tableTotalItems: number | string = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: 'id',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = false
  searchCountryDebounce: Function
  searchProvinceDebounce: Function
  searchCityDebounce: Function
  ownedItems: any = [
    {
      text: 'Yes',
      value: true
    },
    {
      text: 'No',
      value: false
    }
  ]
  ownedValue: boolean = false
  isEdit: boolean = false
  idEdited: any = null
  idDeleted: any = null
  alertWarehouseDelete: boolean = false

  async mounted() {
    await this.getWarehouses()
  }

  // @Watch('filterSelected')
  // onFilterSelectedChanged() {
  //   if (this.filterSelected !== null) this.getOneWarehouse(this.filterSelected)
  //   else this.getWarehouses()
  // }
  // @Watch('searchInput')
  // onSearchInput() {
  //   this.getWarehouses()
  // }

  // @Watch('tablePagination', { deep: true })
  // onChanged() {
  //   this.getWarehouses()
  // }

  @Watch('warehouseDialogData.keywordCountry')
  async onChanged() {
    this.$vuetify.goTo(0)
    if(this.warehouseDialogData.keywordCountry == ''){
      this.warehouseCountryId = null
      this.searchCountryDebounce = debounce(this.getCountry, 500)
      this.searchCountryDebounce()
    }else if(this.warehouseCountryId == null || (find(this.countryOriginItems, item => item.id == this.warehouseCountryId) && find(this.countryOriginItems, item => item.id == this.warehouseCountryId).name != this.warehouseDialogData.keywordCountry)){
      this.searchCountryDebounce = debounce(this.getCountry, 500)
      this.searchCountryDebounce()
    }
  }

  @Watch('warehouseDialogDataProvince.keywordProvince')
  async onProvinceChanged() {
    this.$vuetify.goTo(0)
    if(this.warehouseDialogDataProvince.keywordProvince == ''){
      this.warehouseProvinceId = null
    }
    if(this.warehouseProvinceId == null || (find(this.provinceOriginItems, item => item.id == this.warehouseProvinceId) && find(this.provinceOriginItems, item => item.id == this.warehouseProvinceId).name != this.warehouseDialogDataProvince.keywordProvince)){
      this.searchProvinceDebounce = debounce(this.getProvinces, 500)
      this.searchProvinceDebounce()
    }
  }

  @Watch('warehouseDialogDataCity.keywordCity')
  async onCityChanged() {
    this.$vuetify.goTo(0)
    if(this.warehouseDialogDataCity.keywordCity == ''){
      this.warehouseCityId = null
    }
    if(this.warehouseCityId == null || (find(this.cityOriginItems, item => item.id == this.warehouseCityId) && find(this.cityOriginItems, item => item.id == this.warehouseCityId).name != this.warehouseDialogDataCity.keywordCity)){
      this.searchCityDebounce = debounce(this.getCities, 500)
      this.searchCityDebounce()
    }
  }

  async getWarehouses() {
    const opts: any = {
      params: {
        'page[num]': this.tablePagination.page,
        'page[limit]': this.tablePagination.rowsPerPage,
        sort: this.tablePagination.descending ? '-id' : 'id',
        include: 'country,province,city'
      }
    }

    // if (this.searchInput != '') {
    //   opts.params = {
    //     ...opts.params,
    //     'filter[name][like]': this.searchInput
    //   }
    // } else {
    //   var textFilter = 'filter[name][like]'
    //   delete opts.params[textFilter]
    // }

    this.tableLoading = true
    try {
      var response = await WarehouseService.getWarehouses(opts)
      var responseData = response.data
      var responseIncluded = response.included

      this.tableItems = []
      forEach(responseData, dataWarehouse => {
        const item = {
          ...dataWarehouse,
          id: dataWarehouse.attributes.id,
          warehouseName: dataWarehouse.attributes.name,
          address: dataWarehouse.attributes.address,
          address_2: dataWarehouse.attributes.address_2 ? dataWarehouse.attributes.address_2 : '-',
          countryName: responseIncluded.country[dataWarehouse.attributes.country].attributes.name,
          provinceName: responseIncluded.province[dataWarehouse.attributes.province].attributes.name,
          cityName: responseIncluded.city[dataWarehouse.attributes.city].attributes.name,
          ownedByAli: dataWarehouse.attributes.owned_by_ali ? 'Yes' : 'No'
        }

        this.tableItems.push(item)
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  async getCountry() {
    this.warehouseDialogData.loadingCountry = true
    this.countryOriginItems = []
    try {
      // if (this.supplierDialogData.countryOrigin === '') {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 25,
          sort: '-created_at',
          'filter[name][like]': ''
        }
      }
      if (!isEmpty(this.warehouseDialogData.keywordCountry)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.warehouseDialogData.keywordCountry
        // opts.params.searchJoin = 'and'
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
        // delete opts.params.searchJoin
      }
      const response = await SuppliersService.getCountry(opts)

      const dataCountry = response.data.data

      for (const countryList of dataCountry) {
        const country: any = {
          name: countryList.attributes.name,
          id: countryList.attributes.id
        }
        this.countryOriginItems.push(country)
      }
      // }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.warehouseDialogData.loadingCountry = false
    }
  }

  async getProvinces() {
    this.warehouseDialogDataProvince.loadingProvince = true
    this.provinceOriginItems = []
    try {
      // if (this.supplierDialogData.countryOrigin === '') {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 25,
          sort: '-created_at',
          'filter[name][like]': '',
          'filter[country_id][is]': this.warehouseCountryId
        }
      }
      if (!isEmpty(this.warehouseDialogDataProvince.keywordProvince)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.warehouseDialogDataProvince.keywordProvince
        // opts.params.searchJoin = 'and'
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
        // delete opts.params.searchJoin
      }
      const response = await ProvincesService.getProvinces(opts)

      const dataProvince = response.data.data

      for (const provinceList of dataProvince) {
        const province: any = {
          name: provinceList.attributes.name,
          id: provinceList.attributes.id
        }
        this.provinceOriginItems.push(province)
      }
      // }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.warehouseDialogDataProvince.loadingProvince = false
    }
  }

  async getCities() {
    this.warehouseDialogDataCity.loadingCity = true
    this.cityOriginItems = []
    try {
      // if (this.supplierDialogData.countryOrigin === '') {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 25,
          sort: '-created_at',
          'filter[name][like]': '',
          'filter[country_id][is]': this.warehouseCountryId,
          'filter[province_id][is]': this.warehouseProvinceId
        }
      }
      if (!isEmpty(this.warehouseDialogDataCity.keywordCity)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.warehouseDialogDataCity.keywordCity
        // opts.params.searchJoin = 'and'
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
        // delete opts.params.searchJoin
      }
      const response = await CityService.getCities(opts)

      const dataCity = response.data.data

      for (const cityList of dataCity) {
        const city: any = {
          name: cityList.attributes.name,
          id: cityList.attributes.id
        }
        this.cityOriginItems.push(city)
      }
      // }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.warehouseDialogDataCity.loadingCity = false
    }
  }

  async addWarehouseDialogOpen(){
    await this.getCountry()
    this.addWarehouseDialog = true
  }

  async openEditWarehouseDialog(data){
    this.showLoading({ text: 'Loading...' })
    this.isEdit = true
    this.idEdited = data.item.id
    this.warehouseDialogData.keywordCountry = data.item.countryName
    this.warehouseDialogDataProvince.keywordProvince = data.item.provinceName
    this.warehouseDialogDataCity.keywordCity = data.item.cityName

    await this.getCountry()
    await this.getProvinces()
    await this.getCities()

    this.warehouseCountryId = this.countryOriginItems[0].id
    this.warehouseProvinceId = this.provinceOriginItems[0].id
    this.warehouseCityId = this.cityOriginItems[0].id
    this.warehouseName = data.item.warehouseName
    this.warehouseAddress = data.item.address
    this.warehouseOptionalAddress = data.item.address_2 == '-' ? null : data.item.address_2
    this.ownedValue = data.item.ownedByAli == 'Yes' ? true : false

    this.addWarehouseDialog = true
    this.closeLoading()
  }

  openDeleteWarehouseDialog(id){
    this.alertWarehouseDelete = true
    this.idDeleted = id
  }

  async warehouseDeleted(){
    try {
      this.showLoading({ text: 'Deleting...' })

      await WarehouseService.deleteWarehouse(this.idDeleted)

      this.showSnackbar({
        text: 'Warehouse Deleted Successfully!',
        color: 'green',
        timeout: 2500
      })

      this.idDeleted = null
      this.alertWarehouseDelete = false
      this.getWarehouses()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  // Add new warehouse
  async addNewWarehouse() {
    try {
      const validationResponse = await this.$validator.validateAll('warehouseDialogScope')
      if (validationResponse) {

        this.addWarehouseDialog = false
        this.showLoading({ text: 'Loading...' })

        const dialogData = {
          name: this.warehouseName,
          address: this.warehouseAddress,
          address_2: this.warehouseOptionalAddress,
          country_id: this.warehouseCountryId,
          province_id: this.warehouseProvinceId,
          city_id: this.warehouseCityId,
          owned_by_ali: this.ownedValue
        }
        // console.log('add payload ', dialogData);

        if(this.isEdit){
          await WarehouseService.editWarehouse(this.idEdited, dialogData)
        }else{
          await WarehouseService.addNewWarehouse(dialogData)
        }

        if(this.isEdit){
          this.showSnackbar({
            text: 'Warehouse Edited Successfully!',
            color: 'green',
            timeout: 2500
          })
        }else{
          this.showSnackbar({
            text: 'Warehouse Created Successfully!',
            color: 'green',
            timeout: 2500
          })
        }
        this.isEdit = false
        this.idEdited = null
        this.warehouseCountryId = null
        this.warehouseProvinceId = null
        this.warehouseCityId = null
        this.warehouseName = null
        this.warehouseAddress = null
        this.warehouseOptionalAddress = null
        this.ownedValue = false
        this.getWarehouses()
      } else {
        this.showSnackbar({
          text: 'Please check all filed(s) requirements',
          color: 'red',
          timeout: 3500
        })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }
}
